<template lang="pug">
.SongListItemRow
	.left
		img.song-img(:src="image" width="48" height="48")
		.song-info
			span.song-name {{ songName }}
			a.song-artist {{ artistString }}
	.right
		SingaButton.is-small.is-transparent-dark.add-to-queue(@click.stop.prevent="addSongToQueue")
			span.font-xs {{ t('general.add') }}
</template>

<script setup lang="ts">
import type { Song } from '~/types'

const useImage = useGetImageSize()
const { t } = useI18n()

const queueStore = useQueueStore()

const { addToQueue } = queueStore
const { queueItemSelection } = useVariantSelection()

const props = defineProps({
	song: {
		type: Object as PropType<Song>,
		required: true
	}
})

const songName = computed(() => props?.song?.name)
const { getAllArtists, getArtistSlugs } = useArtistNames(props?.song?.artists)
const artistString = computed(() => {
	return getAllArtists()
})
const image = computed(() => {
	return useImage(props?.song?.image, 'medium', 'song', true)
})

const addSongToQueue = async () => {
	const artistSlug = getArtistSlugs()
	const link = `/artists/${artistSlug}/${props.song.slug}/${props.song.hash}`
	const queueItem = await queueItemSelection(props.song, undefined, undefined, t('song.error.play'))
	if (!queueItem) { return }
	await addToQueue(queueItem, link, 'Song list item player search', false, t('song.error.queue'))
}
</script>

<style lang="sass" scoped>
.SongListItemRow
	padding: $spacing-16
	display: inline-block
	border-radius: $radius-default
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between
	position: relative
	&:not(.queue-playing)
		.playing-text
			display: none
	&.queue-playing
		.add-to-queue
			display: none
	&:not(:only-child):not(:last-child)
		&::after
			content: ''
			position: absolute
			bottom: 0
			left: 82px
			right: 0
			width: calc(100% - 80px)
			height: 1px
			background-color: $transparent-white-8
.left
	display: flex
	align-items: center
	justify-content: flex-start
.right
	display: flex
	align-items: center
.song-img
	border-radius: $radius-default
	width: 48px
	height: auto
	margin-right: $spacing-16
.song-name
	@include font(basier, medium)
	color: $color-grey-30
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	display: block
.song-artist
	color: $color-grey-60
	white-space: nowrap
	text-overflow: ellipsis
	min-width: 0
	overflow: hidden
	padding-right: $spacing-4
	display: block
button.is-small
	height: 40px
.song-info
	width: 250px
	padding-right: $spacing-16
.queue-playing
	.song-name
		color: $primary
		@include fontSize(m)
		@include font(basier, medium)
	.song-artist
		color: $color-green-80
	.playing-text
		color: $primary
</style>

<style lang="sass">
.SongListItemRow
	.like-song
		svg
			path
				stroke: $color-grey-30
		&:hover, &:active
			svg
				path
					fill: $color-grey-30
</style>
