<template lang="pug">
div
	// Queue view
	div(v-if="!songSettingsOpen")
		.header
			transition(name="fade")
				.header-texts(v-show="showHeaderText")
					span.queue-title {{ t('player.queue') }}
					div(v-if="!isQueueLoading")
						span.songs-info(v-if='hasStartedPlaying && currentQueue.length === 0 && !isQueueLoading') {{ t('player.queueIsEmpty') }}
						span.songs-info(v-else) {{ t('playlists.songs', { count: hasStartedPlaying ? currentQueue.length : queue.length }) }} · {{ hasStartedPlaying ? currentQueueLengthMinutes : queueLengthMinutes }}
			SearchQueueSearchInput.search-input(
				@filterChanged="setSearchString"
				@focus="toggleSearch(true)"
				@focusout="handleFocusOut"
				@mousedown="handleMouseDown"
				@mouseup="handleMouseUp"
				:queueMode="true"
				:class="{'expanded': showSearch, 'expanding': expandingSearchBar}"
				:expanded="showSearch"
				ref="searchInput"
				v-if="!showDemoPlayer"
			)
		.loading-wrap
			CommonLoadingAnimation(v-if="isLoading && !scrollToLoad")
		.search-songs(v-if="searchString")
			SongItemPlayerSearch(v-for="song in songs" :key="song.id" :song="song" :searchMode="true")
			.load-more-wrap(ref="loadMoreTrigger" v-if="songs.length > 0")
				SingaButton.is-regular.is-transparent-dark(@click="loadMoreContent()" v-if="nextPage && !scrollToLoad && !isLoading") {{ t('general.loadMore') }}
				CommonLoadingAnimation(v-else-if="scrollToLoad && isLoading")
			.empty-queue-wrap(v-if="searchString && songs && songs.length === 0 && !isLoading")
				.truncate
					span {{ t('search.term.notFound') }} "{{searchString}}" :(
		template(v-else-if="isSearchView && !searchString && historySongs.length > 0")
			.recently-sung
				span.recently-sung-text {{ t('player.queue.recentlySung') }}
			SongItemPlayerSearch(v-for="song in historySongs" :key="song.id" :song="song" layout="row")
			SingaButton.is-full-ghost.see-all-button(v-if="response?.nextPage" @click="goToSongHistory()") {{ t('general.seeAll') }}
		template(v-else-if="hasSongs && !isSearchView")
			ol.queue-songs(ref="queueElement")
				li
					SongItemMiniQueue(v-if="firstInQueue" isFirstInQueue="true" :song="firstInQueue" class="queue-playing")
				draggable(
					v-if="hasSongs && !isQueueLoading"
					v-model="currentQueue"
					:animation="200"
					handle='.draggable-item'
					ghost-class="ghost"
					class="draggable"
					item-key="entry.id"
					:component-data="{ name: 'list' }"
				)
					template(#item="{ element }")
						li.drag-animation
							SongItemMiniQueue.draggable-item(:song="element" :timeUntilSong="timeUntilThisSongInQueue(element.entryID)" :key="element.entry.id")
				li(v-else-if="isQueueLoading")
					SingaSkeleton.skeleton(:type="'Song'" :layout="'row'" :listAmount="10" :is-queue="true")

		.button-wrap(v-if="hasSongs && !searchString && restOfQueue.length && !isSearchView")
			transition(name="fade")
				SingaButton.empty-queue.is-transparent-dark.rounded(v-if="!confirmEmptyQueue" icon-left="trash-outline" size="small" @click="confirmEmptyQueue = true") {{ t('player.emptyQueue') }}
			transition(name="fade")
				.confirmation-step(v-if="confirmEmptyQueue")
					span {{ t('player.emptyQueue.confirmation') }}
					SingaButton.is-transparent-dark.rounded(size="small" @click="confirmEmptyQueue = false") {{ t('general.cancel') }}
					SingaButton.is-transparent-dark.rounded.empty-button(size="small" @click="emptyQueue(); confirmEmptyQueue = false ") {{ t('player.emptyQueue.short') }}
		template(v-else-if="!hasSongs && !isSearchView")
			.empty-queue-wrap
				span {{ t('player.queueIsEmpty') }}
		.demo-player-cta(v-if="showDemoPlayer")
			img(src="~/assets/images/queue_placeholder.svg")
			img(src="~/assets/images/queue_placeholder.svg")
			span.demo-cta-text.top {{ t('miniQueue.demoPlayer.cta') }}
			template(v-if="!isLoggedIn")
				NuxtLink(:to="localePath('/login')")
					SingaButton.is-primary.is-regular {{ t('button.demoPlayer.cta') }}
				span.demo-cta-text {{ t('miniQueue.demoPlayer.cta.subtitle') }}
			template(v-else)
				NuxtLink(to="https://singa.com/choose-plan" external)
					SingaButton.is-primary.is-regular {{ t('general.getPremium') }}

	// Song settings view
	.song-settings-view(v-else-if="!reportingView")
		.settings-texts
			SingaButton.is-icon.back-button(@click="songSettingsOpen = false; versionSelectorOpen = false" :icon-left="'arrow-back'")
			span.queue-title {{ t('song.settings.title') }}
		.SongListItemRow.song-item
			.left
				.song-img
					span.queue-position {{ getSongPosition(songInQueue.entryID) }}
					img(:src="songInQueue?.entry?.image?.tiny?.url" width="48" height="48")
				.song-info
					span.song-name {{ songInQueue.entry.name }}
					a.song-artist {{ songArtists }}
			.buttons
				SongContextMenu.margin(
					:isQueueItem="true"
					:song="songInQueue.entry"
					:is-list-item="false"
					:id="songInQueue.entry.id"
					layout="column"
					:link="link"
					accessPoint="Player queue item"
					)
		.settings-area(:class="{'add-padding': addPaddingForScrollBar}")
			.settings-row.version-settings.clickable-field.version-multi.regular(v-if="songHasMultipleVariants && !versionSelectorOpen")
				SingaField(horizontal grouped labelFor="versionSelect" :label="t('settings.version')")
				SingaButton.is-ghost.version-title(id="versionSelect" @click="versionSelectorOpen = !versionSelectorOpen") {{ songInQueue.variant.catalog.name }}
				SingaButton.version-button.is-ghost(id="versionSelect" @click="versionSelectorOpen = !versionSelectorOpen" :icon-right="versionSelectorOpen ? 'chevron-up' : 'chevron-down'")
			.settings-row.version-settings.regular(v-else-if="!songHasMultipleVariants && !versionSelectorOpen")
				SingaField.version-field(horizontal :label="t('settings.version')")
				span.version-title.only-version {{ songInQueue.variant.catalog.name }}
			.settings-row.version-selector(v-else)
				.version-settings.selector-open
					SingaField.version-selector-field.selector-title(horizontal labelFor="openedVersionSelect" :label="t('settings.version')")
					SingaButton.is-full-ghost(v-if="songHasMultipleVariants" id="openedVersionSelect" @click="versionSelectorOpen = !versionSelectorOpen" :icon-right="versionSelectorOpen ? 'chevron-up' : 'chevron-down'" size="medium")
				.versions
					SongQueueSongVersions(:versions="songInQueue.entry.variants" :queueEntryID="songInQueue.entryID" :song="songInQueue.entry" :isSongSettings="true" :selectedVersionID="songInQueue.variant.id" @close="versionSelectorOpen = false")
			.other-settings
				.settings-row.regular.setting-toggle.clickable-field(v-if="hasVocals" :class="{'disabled-demo-player': showDemoPlayer}")
					SingaField(v-if="!showDemoPlayer" @click="toggleVocals(!vocals)")
						SingaSwitch(:modelValue="vocals" :label="t('settings.vocals')" @toggle="toggleVocals")
					SingaField(v-else)
						SingaSwitch(:modelValue="false" :label="t('settings.vocals')")
				.settings-row.regular.setting-toggle(v-else)
					SingaField(horizontal :label="t('settings.vocals')")
						span.na-text {{ t('general.notAvailable') }}
				.settings-row
					SingaField.pitch-and-volume-field(grouped :label="t('settings.pitch')")
						.number-input
							SingaButton.is-full-ghost(icon-left="remove" @click="decrementPitch()" :disabled="itemPitch === -4")
							span {{ itemPitch }}
							SingaButton.is-full-ghost(icon-left="add" @click="incrementPitch()" :disabled="itemPitch  === 4")
				.settings-row.clickable-field(:class="{'disabled-demo-player': showDemoPlayer}")
					SingaField.report-song(horizontal labelFor="reporting" :label="t('songError.report.subtitle')")
						SingaButton.is-icon.report-button(:icon-right="'chevron-forward'" id="reporting" @click="reportingView = true")
	// Song error reporting view
	SongErrorReporting(v-if="reportingView" :song="songInQueue")
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@vueuse/core'
import draggable from 'vuedraggable'
import { storeToRefs } from 'pinia'
import SongErrorReporting from './SongErrorReporting.vue'
import { useQueueStore } from '~/pinia/queueStore'
import { useSongHistory } from '~~/composables/library/useSongHistory'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import type { LoadSongsResult, Song, SongListResponse } from '~~/types'

const { loadSongs: loadSongHistory } = useSongHistory()

const { t } = useI18n()
const queueStore = useQueueStore()

const geolocationStore = useGeoLocationStore()
const { contentMarket } = storeToRefs(geolocationStore)

const { emptyQueue, setQueue, editQueueItem, addToChangedPreferences } = queueStore
const { firstInQueue, hasSongs, restOfQueue, queue, queueLengthPrecise, restOfQueueLengthPrecice, isQueueLoading } = storeToRefs(queueStore)

const audioPlayerStore = useAudioPlayerStore()
const hasStartedPlaying = computed(() => audioPlayerStore.currentSeconds > 0)

const searchString = ref('')
const scrollToLoad = ref(false)
const page = ref(1)
const loadMoreTrigger = ref()

const getSearchParams = () => ({
	search: searchString.value,
	page: page.value,
	page_size: 10,
	type: 'songs'
})

// Search functionality
const searchKey = computed(() => `queue-search-${searchString.value}-${page.value}-${contentMarket.value?.market}`)
const { data: searchResponse, execute: executeSearch, status, refresh } = await useAsyncData<LoadSongsResult>(
	searchKey.value,
	async () => {
		if (!searchString.value) return { results: [], page: 1, nextPage: false, count: 0 }
		try {
			const searchStore = useSearchStore()
			await searchStore.loadDetailSearch(getSearchParams())
			const { getSongs } = storeToRefs(searchStore)
			const songResponse = getSongs.value as SongListResponse
			return {
				results: songResponse?.results || [],
				page: page.value,
				nextPage: Boolean(songResponse?.next),
				count: songResponse?.results?.length || 0
			} satisfies LoadSongsResult
		} catch (error) {
			console.error('Search failed:', error)
			return { results: [], page: 1, nextPage: false, count: 0 }
		}
	},
	{
		server: false,
		immediate: false,
		watch: [page],
		default: () => ({ results: [], page: 1, nextPage: false, count: 0 })
	}
)

watch(contentMarket, (newVal, oldVal) => {
	if (newVal.market?.toLowerCase() !== oldVal.market?.toLowerCase()) {
		refresh()
	}
})

// Computed properties
const songs = computed(() => searchResponse.value?.results || [])
const nextPage = computed(() => searchResponse.value?.nextPage || false)
const isLoading = computed(() => status.value === 'pending')

const userStore	= useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)

const { isLoggedIn } = useAuth()

const reportingView = ref(false)

const queueLengthMinutes = computed(() => {
	const queueLength = queueLengthPrecise.value
	const minutes = Math.floor(queueLength / 60)
	const seconds = queueLength - minutes * 60
	return `${minutes} ${t('player.queue.minutes')} ${seconds} ${t('player.queue.seconds')}`
})

const currentQueueLengthMinutes = computed(() => {
	const queueLength = restOfQueueLengthPrecice.value
	const minutes = Math.floor(queueLength / 60)
	const seconds = queueLength - minutes * 60
	return `${minutes} ${t('player.queue.minutes')} ${seconds} ${t('player.queue.seconds')}`
})

const isSearchView = ref()
const searchInput = ref()
const showSearch = ref()
const showHeaderText = ref(true)
const expandingSearchBar = ref(false)

const vocals = ref(false)

const localePath = useLocalePath()
const historySongs = ref<Song[]>([])
const response = ref()

const loadHistorySongs = async () => {
	if (historySongs.value.length === 0) {
		response.value = await loadSongHistory({ page_size: 5 }, 0, true)
		historySongs.value = response.value.results
	}
}

const songSettingsOpen = ref(false)
const songInQueue = ref()
const versionSelectorOpen = ref(false)
const addPaddingForScrollBar = ref(false)

const link = ref('')

const toggleSearch = async (expand: boolean) => {
	loadHistorySongs()
	if (expand) {
		expandingSearchBar.value = true
		isSearchView.value = true
		setTimeout(() => {
			expandingSearchBar.value = false
		}, 300)
	} else {
		isSearchView.value = false
	}
}

const handleFocusOut = () => {
	if (!expandingSearchBar.value) {
		toggleSearch(false)
	}
}

const hasVocals = ref(false)

const handleMouseDown = () => {
	expandingSearchBar.value = true
}

const handleMouseUp = () => {
	expandingSearchBar.value = false
}

watch(isSearchView, async (value) => {
	if (value) {
		showHeaderText.value = false
		await nextTick()
		showSearch.value = true
	} else {
		showSearch.value = false
		await nextTick()
		setTimeout(() => showHeaderText.value = true, 200)
	}
})

const currentQueue = computed({
	get() {
		return restOfQueue.value
	},
	set(value) {
		setQueue([firstInQueue.value, ...value])
	}
})

const goToSongHistory = () => {
	useEventEmit('player:closeSettings')
	navigateTo(localePath('/my-library?tab=song-history'))
}

// Find the right queue entry, and return -1 if it's not found
const timeUntilThisSongInQueue = (songId: string) => {
	let time = 0
	const fullQueue = [firstInQueue.value, ...currentQueue.value]

	for (let i = 0; i < fullQueue.length; i++) {
		if (fullQueue[i] && fullQueue[i].entryID === songId) {
			const minutes = Math.round(time / 60)
			return `${minutes} ${t('player.queue.minutes')}`
		}
		time += fullQueue[i].entry.duration
	}
	return '-1'
}

const confirmEmptyQueue = ref(false)

// This makes sure the unexpanded search input is blurred if the user closes the input with the escape key
watch(isSearchView, (value) => {
	if (!value && searchInput.value) {
		(document.activeElement as HTMLElement)?.blur()
	}
})

// Search input handler
const setSearchString = async (value: string) => {
	searchString.value = value
	page.value = 1
	scrollToLoad.value = false
	await executeSearch()
}

// Handle infinite scroll
useInfiniteScroll(
	loadMoreTrigger,
	() => {
		if (nextPage.value && !isLoading.value && scrollToLoad.value) {
			page.value++
		}
	},
	{ distance: 10, interval: 1000 }
)

const loadMoreContent = () => {
	scrollToLoad.value = true
	page.value++
}

const getQueueItemPitch = (entryID: string) => {
	const item = queue.value.find(n => n.entryID === entryID)
	return item?.pitch || 0
}
const getQueueItemVocals = (entryID: string) => {
	const item = queue.value.find(n => n.entryID === entryID)
	return item?.vocals || false
}

const songHasMultipleVariants = ref(false)

const itemPitch = ref(0)

const checkSettings = () => {
	itemPitch.value = getQueueItemPitch(songInQueue.value.entryID)
	vocals.value = getQueueItemVocals(songInQueue.value.entryID)
	hasVocals.value = songInQueue.value.variant.has_cover
}

const decrementPitch = () => {
	itemPitch.value = Math.max(itemPitch.value - 1, -4)
	editQueueItem({ entryID: songInQueue.value.entryID, pitch: itemPitch.value })
	addToChangedPreferences(songInQueue.value.entryID)
}
const incrementPitch = () => {
	itemPitch.value = Math.min(itemPitch.value + 1, 4)
	editQueueItem({ entryID: songInQueue.value.entryID, pitch: itemPitch.value })
	addToChangedPreferences(songInQueue.value.entryID)
}

const toggleVocals = (value: boolean) => {
	editQueueItem({ entryID: songInQueue.value.entryID, vocals: value })
	addToChangedPreferences(songInQueue.value.entryID)
	vocals.value = value
}

const getSongPosition = (entryID: string) => {
	return queue.value.findIndex(song => song.entryID === entryID)
}

useEventOn('search:clearSearch', () => {
	setSearchString('')
})

const songArtists = ref()
const songVersionCatalogName = ref()

useEventOn('songSettings:versionChanged', (variant) => {
	if (!songInQueue.value) {
		console.log('no song in queue')
		return
	}
	songVersionCatalogName.value = variant.catalog.name
	songInQueue.value.variant = variant
	queueStore.changedPreferences.push(songInQueue.value.entryID)
	checkSettings()
})

useEventOn('queue:openSongSettings', async (song) => {
	songInQueue.value = song
	const { getAllArtists, getArtistSlugs } = useArtistNames(song.entry.artists)
	songArtists.value = getAllArtists()
	const artistSlug = getArtistSlugs()
	songHasMultipleVariants.value = songInQueue.value.entry.variants.length > 1
	addPaddingForScrollBar.value = songInQueue.value.entry.variants.length > 2
	songVersionCatalogName.value = songInQueue.value.variant.catalog.name
	checkSettings()
	link.value = `/artists/${artistSlug}/${song.entry.slug}/${song.entry.hash}`
	songSettingsOpen.value = true
})

useEventOn('songSettings:closeErrorReporting', () => {
	reportingView.value = false
})

const queueElement = ref()

const contextMenuHandler = useDebounceFn(() => {
	useEventEmit('contextMenu:close')
}, 10)

onMounted(() => {
	if (queueElement.value) {
		queueElement.value.addEventListener('scroll', contextMenuHandler)
	}
})

onUnmounted(() => {
	if (queueElement.value) {
		queueElement.value.removeEventListener('scroll', contextMenuHandler)
	}

	useEventOff('search:clearSearch')
	historySongs.value = []
	useEventOff('queue:openSongSettings')
})
</script>

<style lang="sass" scoped>
.header
	display: flex
	flex-direction: row
	align-items: center
	position: relative
	height: 53px
	margin-bottom: $spacing-16
	width: 100%
	&:not(.expanded)
		&::after
			content: ''
			position: absolute
			margin-top: $spacing-8
			top: 100%
			width: 100%
			height: 1px
			background-color: $transparent-white-8

.header-texts
	display: flex
	flex-direction: column
	@include fontSize(s)
	color: $color-grey-60
	width: 90%

.fade-enter-active
	transition: opacity .3s
.fade-leave-active
	transition: opacity .3s

.fade-enter, .fade-leave-to
	opacity: 0

.queue-title
	@include font(basier, semiBold)
	@include fontSize(xl)
	color: $color-grey-30
.search-songs, .queue-songs
	max-height: calc(100vh - 328px) // 100vh + (player bar and other queue elements + spacing top and bottom)
	overflow-x: hidden
	overflow-y: auto
	margin-top: $spacing-8
.load-more-wrap
	text-align: center
	margin: $spacing-32 0 $spacing-32
.empty-queue-wrap
	display: flex
	align-items: center
	justify-content: center
	min-height: 200px
.button-wrap
	padding: $spacing-4 0
	text-align: center
	height: 56px

.settings-area
	max-height: 500px !important
	overflow-y: auto
	overflow-x: hidden
	&.add-padding
		padding-right: $spacing-8
	&::-webkit-scrollbar
		width: 8px
	&::-webkit-scrollbar-track
		background: $transparent-white-4
	&::-webkit-scrollbar-thumb
		background: $transparent-white-12
		border-radius: 4px

.truncate
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap
	min-width: 0
	max-width: calc(100% - $spacing-8)

.queue-songs
	counter-reset: song-counter 0

.queue-position
	position: absolute
	top: 50%
	left: 50%
	z-index: 10
	transform: translate(-50%, -50%)
	content: counter(song-counter)
	@include font(basier, medium)
	color: $color-grey-10
	@include fontSize(s)

.draggable-item
	cursor: grab
.draggable-item:active
	cursor: grabbing

.button-wrap
	display: flex
	justify-content: flex-end
	.empty-queue
		margin-top: $spacing-8
		position: absolute
		right: $spacing-16
		&:hover
			background-color: $color-red-70 !important

.demo-player-cta
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	height: 360px
	padding: $spacing-16
	border-radius: $radius-default
	width: 100%
	background-color: rgba($color-grey-90, 0.05)
	gap: $spacing-16

.demo-cta-text
	@include font(basier, regular)
	@include fontSize(s)
	color: $color-grey-30
	text-align: center
	&.top
		max-width: 350px

.see-all-button
	color: $color-grey-50
	text-decoration: underline

.recently-sung
	padding-top: $spacing-8
	margin-left: $spacing-16
	.recently-sung-text
		color: $color-grey-30
		@include font(basier, semiBold)
		@include fontSize(s)

.SongListItemRow
	padding: $spacing-16
	display: inline-block
	border-radius: $radius-default
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between
	position: relative
	&:not(.queue-playing)
		.playing-text
			display: none
	&.queue-playing
		.add-to-queue
			display: none
	&:not(:only-child):not(:last-child)
		&::after
			content: ''
			position: absolute
			bottom: 0
			left: 82px
			right: 0
			width: calc(100% - 80px)
			height: 1px
			background-color: $transparent-white-8
.left
	display: flex
	align-items: center
	justify-content: flex-start
.right
	display: flex
	align-items: center

.song-img
	border-radius: $radius-default
	width: 48px
	height: 48px
	margin-right: $spacing-16
	background-color: $transparent-white-4
	overflow: hidden
	position: relative
	&:before
		content: ""
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: rgba($transparent-black-56, 0.5)
.song-name
	@include font(basier, medium)
	color: $color-grey-30
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	display: block
.song-artist
	color: $color-grey-60
	white-space: nowrap
	text-overflow: ellipsis
	min-width: 0
	overflow: hidden
	padding-right: $spacing-4
	display: block
.song-info
	width: 250px
	padding-right: $spacing-16

:deep(.is-full-ghost)
	margin: 0
	padding: 0
	background-color: transparent
	color: $color-grey-20

.number-input
	width: 100%
	height: 40px
	display: flex
	align-items: center
	justify-content: space-between
	background-color: $transparent-white-12
	padding: 0 $spacing-16
	border-radius: $radius-round
	button
		color: $transparent-white-40
		&:hover, &:focus, &:active
			color: white
	.button:focus:not(:active):first-child, .button.is-focused:not(:active):first-child
		border-radius: 100px 0 0 100px
	.button:focus:not(:active):last-child, .button.is-focused:not(:active):last-child
		border-radius: 0 100px 100px 0

:deep(.field-body)
	display: flex
	flex-direction: column
	align-items: flex-end

:deep(.field-label)
	margin-right: 0

:deep(.label)
	color: $color-grey-30

.version-text:not(.only-version)
	margin-right: $spacing-24

.version-settings
	display: flex
	width: 100%
	justify-content: space-between
	color: $color-grey-30

.settings-row
	background-color: $transparent-white-8
	margin: $spacing-8 0 $spacing-8 0
	padding: 14px 20px
	border-radius: $radius-default
	&:hover
		&.clickable-field
			background-color: $transparent-white-12
	&.version-selector
		margin-top: 0px
	&:last-child
		margin-bottom: 0
	&.regular
		height: 52px
	&.setting-toggle
		.field
			display: flex
			height: 100%
		:deep(.field-label)
			margin-bottom: 0
	@media (min-width: $tablet)
		.field
			height: 100%

.song-item
	padding: $spacing-8 0
	margin-bottom: $spacing-8
	&::after
		display: none

.report-song
	:deep(.label)
		width: 350px

.settings-texts
	display: flex
	gap: $spacing-8
	margin-bottom: $spacing-8

.version-settings
	align-items: flex-start

.version-selector
	display: flex
	flex-direction: column
	width: 100%
	.version-settings
		display: flex
		align-items: center
		justify-content: space-between
		width: 100%

.versions
	width: 100%
	height: auto
	position: relative
	z-index: 1

.na-text
	color: $color-grey-50
.report-button, .version-settings
	&:deep(.icon)
		color: $transparent-white-48

.drag-animation
	transition: opacity 0.1s ease-out

.settings-row:not(.clickable-field)
	cursor: default !important

.only-version
	padding: 0
	color: $color-grey-50
	cursor: default

.clickable-field
	cursor: pointer !important
	:deep(label)
		cursor: pointer !important

:deep(.switch)
	width: 100% !important
	display: flex
	justify-content: space-between

.version-button
	padding: 0
	color: $color-grey-30
	position: relative
	z-index: 1
	&:hover
		color: $color-grey-30

.is-ghost
	&:hover
		background-color: transparent !important
		text-decoration: none !important

.version-multi
	display: flex
	justify-content: space-between
	position: relative
	margin-bottom: 0
	align-items: center
	.version-title
		position: absolute
		left: 50%
		transform: translateX(-50%)
	.version-label, .version-button, .version-title
		width: 33.33%
	.version-button
		:deep(.button-wrapper)
			margin-left: auto

:deep(.field)
	margin-bottom: 0

.selector-title
	:deep(.label)
		cursor: pointer !important

.selector-open
	margin-bottom: $spacing-8

.loading-wrap,
:deep(.active),
:deep(.spinner)
	height: calc(100% - 80px)

:deep(.spinner)
	z-index: 1
	position: absolute
	top: 80px
	bottom: 0
	left: 0
	right: 0
	align-items: center
	background: rgba(black,.3)

.confirmation-step
	padding-top: $spacing-8
	display: flex
	flex-direction: row
	align-items: baseline
	justify-content: flex-end
	gap: $spacing-8
	.empty-button
		background-color: $color-red-70
		&:hover
			background-color: $color-red-80 !important
	span
		color: $color-grey-60
		@include font(basier, regular)
		@include fontSize(s)
		margin-right: $spacing-8

.fade-enter-active, .fade-leave-active
	transition: opacity .3s ease

.fade-enter, .fade-leave-to
	opacity: 0
</style>
