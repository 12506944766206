<template lang="pug">
div
	.up-next(v-if="hasNextSongInQueue && showPlayer && !showDemoPlayer" @click="nextUpClick()" :class="{ 'show-next-up': nearSongEnd }")
		.play
			.img(v-if="coverImage")
				img(:src="coverImage")
			SingaIcon.play-icon(icon="play" size="medium")
		.texts(v-if="nearSongEnd")
			p.up-next-title {{ t('player.queue.upNext')}}
			p.up-next-song {{ queue[1].entry?.name }}
			p.up-next-artist {{ songArtists }}
	.MiniPlayer.mobile-controls
		SingaField.progress-wrap
			PlayerSeekBar(:disabled="isMediaLoading || playerLoading || !hasSongPlaying")
		.wrap(@click="toggleShowPlayer()")
			.song-info-wrap(:class="{ 'preview-playing': hasSongPlaying, 'bypass-hover': mouseOnLink }")
				.mini-preview(:style="stylesForMiniImage")
					.expand-icon(v-if="!isSecondScreenActive")
						TransitionGroup(name="fade")
							.icon-and-text-wrapper(v-if="showPlayer")
								SingaIcon.grey(size="medium" icon="chevron-down-circle")
								span.grey {{ expandText }}
							.icon-and-text-wrapper(v-else)
								SingaIcon.grey(size="medium" icon="chevron-up-circle")
								span.grey {{ expandText }}
					.playing-in-second-screen(v-else)
						TransitionGroup(name="fade")
							SingaIcon.green(size="large" icon="tv-outline")
					Transition(name="fade")
						.absolute(v-if="isMediaLoading || playerLoading || countDownActive")
							CommonLoadingAnimation(:size="20")
						.absolute(v-else-if="hasSongPlaying && hasSongStarted")
							.playing(:class="{'animation-paused': !isAudioPlaying}")
								span(v-for="i in 5" :key="i")

				.song-info(:class="{ 'song-info-empty': !nowPlaying }")
					template(v-if="nowPlaying")
						NuxtLink.song-title(
							:to="localePath(nowPlaying.songObject.link)"
							@click.stop.prevent="minimizePlayer"
							@mouseenter="mouseOnLink = true"
							@mouseleave="mouseOnLink = false"
							translate="no")
							| {{ nowPlaying.songObject.name }}
						.song-info-bottom
							.song-artists-wrap
								NuxtLink.song-artist(
									:to="localePath(artist.link)"
									@click.stop.prevent="minimizePlayer"
									:title="artist.name"
									v-for="(artist, i) in nowPlaying.artistObjects"
									:key="i"
									@mouseenter="mouseOnLink = true"
									@mouseleave="mouseOnLink = false"
									translate="no")
									| {{ artist.name }}
									span(v-if="i + 1 != nowPlaying.artistObjects.length") ,&nbsp;
					span(v-else) {{ t('player.addToQueue') }}
				.song-controls(v-if="!showDemoPlayer && hasSongPlaying && nowPlaying")
					SongFavoriteButton.favorite-button(
						@click.stop
						:id="nowPlaying?.id"
						:resourceId="nowPlaying?.resource_id"
						:title="nowPlaying?.songObject.name"
						:link="localePath(nowPlaying.songObject.link)"
						:isJustIcon="true"
						accessPoint="Mini player"
					)
					SongContextMenu.ghost-button(
						@click.stop.prevent="minimizePlayer"
						:song="firstInQueue?.entry"
						:link="nowPlaying.songObject.link"
						:isMiniPlayer="true"
						:id="firstInQueue?.entry?.id"
						layout="row"
						accessPoint="Mini player"
						position="top"
						menu-class="mini-player"
					)
			.player-controls
				SingaButton.restart.mini-queue-button.is-transparent-dark(name="rewind song" v-if="!isMobile" @click="rewindSong(); setActiveItem(null)" @mouseenter="isInside()" @mouseleave="isOutside()" :disabled="!hasSongPlaying")
					SingaIcon(icon="play-skip-back")
					p {{ t('player.rewind') }}
				SingaButton.play.mini-queue-button.is-transparent-dark(name="play song" v-if="!playing" @click="pressPlay(); setActiveItem(null)" @mouseenter="isInside()" @mouseleave="isOutside()" :disabled="!hasSongs")
					span(v-if="(!playerLoading && !waitingForNextSong || isPausedByAutoPlay) && !countDownActive")
						SingaIcon(icon="play")
						p {{ t('player.play') }}
					span(v-else)
						CommonLoadingAnimation(:size="28")
				SingaButton.play.mini-queue-button.is-transparent-dark(name="pause song" v-if="playing" @click="pressPause(); setActiveItem(null)" @mouseenter="isInside()" @mouseleave="isOutside()")
					SingaIcon(icon="pause")
					p {{ t('player.stop') }}
				SingaButton.skip.mini-queue-button.is-transparent-dark(name="next song" v-if="!isMobile" @click="goToNextSong(); setActiveItem(null)" @mouseenter="isInside()" @mouseleave="isOutside()" :disabled="!hasSongs || playerLoading || waitingForNextSong && !hasSongPlaying")
					SingaIcon(icon="play-skip-forward")
					p {{ t('player.next') }}
			.queue-settings
				.freemium-songs-counter(v-if="!showDemoPlayer && !isMobile && !hasPremiumSub")
					SingaButton.freemium-button.is-pill.is-rounded.is-primary.nav-dropdown-button(
						@mouseenter="freemiumButtonHover = true"
						@mouseleave="freemiumButtonHover = false"
						:href="settings?.content?.get_premium_link?.cached_url"
						:target="settings?.content?.get_premium_link?.target"
						tag="a"
						translate="no") {{ freemiumText }}
				.demo-cta(v-if="showDemoPlayer && !isMobile && firstInQueue")
					NuxtLink(:to="localePath('/login')")
						SingaButton.is-primary.demo-cta-button {{  t('button.demoPlayer.cta') }}
				SingaButton.settings.mini-queue-button.is-transparent-dark(name="player settings" :disabled="!firstInQueue" @click="setActiveItem(PlayerMenus.SETTINGS)" @mouseenter="isInside()" @mouseleave="isOutside()")
					SingaIcon(icon="settings")
					p {{ t('player.settings') }}
				SingaButton.queue.mini-queue-button.is-transparent-dark(name="player queue" v-if="!isMobile" @click="setActiveItem(PlayerMenus.QUEUE)" @mouseenter="isInside()" @mouseleave="isOutside()")
					CommonLoadingAnimation.queue-loading(:size="24" v-if="isQueueLoading")
					.queue-circle(:class="{ 'animate': animateQueue }" v-else-if="restOfQueue.length > 0 && !isQueueLoading")
						span {{ restOfQueue.length > 999 ? '999+' : restOfQueue.length }}
					SingaIcon(icon="list")
					p {{ t('player.queue') }}

			.settings-wrap(:class="{'is-mobile': isMobile }" v-if="activeItem === 'settings'" @mouseenter="isInside()" @mouseleave="isOutside()" ref="target")
				PlayerSettings
			.queue-wrap#queue-wrap(v-if="activeItem === 'queue' || queueContextMenuOpen && !isMobile" @mouseenter="isInside()" @mouseleave="isOutside()" ref="target")
				PlayerQueue
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

import { onClickOutside, onKeyStroke, useActiveElement } from '@vueuse/core'
import { useQueueSearch } from '~/composables/useQueueSearch'
import { useMediaFileStore } from '~/pinia/mediaFileStore'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'
import { useQueueStore } from '~/pinia/queueStore'
import { useSecondScreenStore } from '~/pinia/secondScreen/secondScreenStore'
import { PlayerNotificationsPopupPlayer } from '#components'

enum PlayerMenus {
	SETTINGS = 'settings',
	QUEUE = 'queue'
}
type Nullable<T> = T | null
const activeItem = ref()
const mouseOnLink = ref(false)

const { t } = useI18n()
const target = ref(null)
const preventHotkeys = ref(true)
const isPausedByAutoPlay = ref(false)

const mediaStore = useMediaFileStore()
const karaokeStore = usePlayerStore()
const audioPlayerStore = useAudioPlayerStore()
const queueStore = useQueueStore()
const userStore	= useUserStore()
const secondScreenStore = useSecondScreenStore()
const { showDemoPlayer } = storeToRefs(userStore)

const { sendMessageToSecondScreen } = secondScreenStore
const { isSecondScreenActive } = storeToRefs(secondScreenStore)

const { playerLoading, showPlayer } = storeToRefs(karaokeStore)
const { setShowPlayer } = karaokeStore

const { rewindSong, nextSong, songStarted } = queueStore
const { isMobile } = useDevice()
const localePath = useLocalePath()

const { queue, firstInQueue, hasSongs, hasSongPlaying, autoplayEnabled, restOfQueue, isQueueLoading } = storeToRefs(queueStore)

const { resumeAudioPlayer, pauseAudioPlayer, skipAudioPlayer, rewindAudioPlayer, increaseVolume, decreaseVolume, setSettingsOpened } = audioPlayerStore
const { playing, isAudioPlaying, hasSongStarted, timeUntilEndDetail } = storeToRefs(audioPlayerStore)

const { allContentLoaded, isMediaLoading } = storeToRefs(mediaStore)

const { searchString } = useQueueSearch()
const expandText = computed(() => showPlayer.value ? t('player.minimize') : t('player.expand'))

const { hasPremiumSub, numberOfFreeSongsLeft } = storeToRefs(userStore)
const { hasPremiumOrFreeSongs } = useUser()

const { $oruga, $preview } = useNuxtApp()
const version = $preview ? 'draft' : 'published'

const { story: settings } = await useStoryblokFetch('settings', {
	version,
	resolve_links: 'url'
})

const isVariantModalOpen = ref(false)

const waitingForNextSong = ref(false)

const activeElement = useActiveElement()

const animateQueue = ref(false)

const queueContextMenuOpen = ref(false)

const bypassAutoPlayActive = ref(false)

const freemiumButtonHover = ref(false)

const countDownActive = ref(false)

const hasNextSongInQueue = computed(() => queue.value.length > 1)

const songArtists = computed(() => {
	const artists = queue.value[1]?.entry?.artists
	return useArtistNames(artists).getAllArtists()
})

const nearSongEnd = computed(() => {
	const [minutes, seconds] = timeUntilEndDetail.value.split(':').map(Number)
	return minutes === 0 && seconds < 15 && seconds > 0
})

const useImage = useGetImageSize()

const coverImage = computed(() => useImage(queue.value[1]?.entry?.image, 'tiny', 'song', false))

useEventOn(('queue:contextMenu'), (value) => {
	queueContextMenuOpen.value = value
})

useEventOn(('player:closeSettings'), () => {
	activeItem.value = null
})

useEventOn(('queue:openPlayerSettings'), () => {
	activeItem.value = PlayerMenus.SETTINGS
})

watch(() => queue.value.length, (newVal: number, oldVal: number) => {
	if (newVal !== oldVal) {
		animateQueue.value = true
		setTimeout(() => animateQueue.value = false, 500) // Reset after 500ms
	}
})

// If active element is an input, disable hotkeys
watch(activeElement, (el: Ref<HTMLElement | null | undefined, HTMLElement | null | undefined>) => {
	if (el instanceof HTMLInputElement
		|| el instanceof HTMLTextAreaElement
		|| el instanceof HTMLSelectElement
		|| el instanceof HTMLButtonElement) {
		preventHotkeys.value = true
	} else {
		preventHotkeys.value = false
	}
})

// If the player is minimized, disable hotkeys
watch(showPlayer, () => {
	preventHotkeys.value = !showPlayer.value
})

// Prevent clicks from toggling play/pause while settings or queue are active
watch(activeItem, () => {
	setTimeout(() => {
		if (activeItem.value) {
			setSettingsOpened(true)
		} else {
			setSettingsOpened(false)
		}
	}, 100)
})

// Escape to minimize
onKeyStroke('Escape', (e: KeyboardEvent) => {
	if (preventHotkeys.value) {
		return
	}
	e.preventDefault()
	setShowPlayer(false)
})

const pressPause = async () => {
	await pauseAudioPlayer(true)
	waitingForNextSong.value = false
	// This GET is here to ensure that the number of free songs is updated in case the user was playing their last free song
	// Otherwise the user could pause and click to go to the next song causing an error
	if (!hasPremiumSub.value && !showDemoPlayer.value) {
		const authCookie = useTokenCookie()
		await userStore.getUser(false, authCookie.value as any)
	}
	if (isSecondScreenActive.value) {
		sendMessageToSecondScreen({ method: 'MANUAL_AUDIO_TOGGLE', paused: true })
	}
}

const pressPlay = async () => {
	if (isMediaLoading.value || playerLoading.value) {
		return
	}
	// Check if paused because of auto play setting
	if (isPausedByAutoPlay.value) {
		useEventEmit('player:bypassAutoPlay')
		isPausedByAutoPlay.value = false
		bypassAutoPlayActive.value = true
	}
	if (!autoplayEnabled.value && !hasSongStarted.value && !isSecondScreenActive.value) {
		countDownActive.value = true
		useEventEmit('player:startPlayer', { countdown: true, bypass: true })
		bypassAutoPlayActive.value = false

		// This is here to keep the player in loading state while countdown is on
		setTimeout(() => {
			countDownActive.value = false
		}, (queueStore.countdownTime + 1) * 1000)
		return
	}
	if (!allContentLoaded.value && (hasPremiumSub.value || hasPremiumOrFreeSongs)) {
		playerLoading.value = true
		useEventEmit('player:startPlayer', { countdown: false, bypass: true })
	// hasSongPlaying is there so that the user could continue a paused song even if it's the last free one
	} else if (hasPremiumOrFreeSongs.value || hasSongPlaying.value) {
		if (!bypassAutoPlayActive.value) {
			resumeAudioPlayer()
			// Start the countdown and add the timer when starting a non-auto play song
		} else {
			useEventEmit('player:countdown')
			setTimeout(() => {
				resumeAudioPlayer()
				bypassAutoPlayActive.value = false
			}, queueStore.countdownTime * 1000)
		}
	} else if (!hasPremiumOrFreeSongs.value) {
		openFreemiumModal()
	}
	if (isSecondScreenActive.value) {
		await sendMessageToSecondScreen({ method: 'MANUAL_AUDIO_TOGGLE', paused: false })
	}
	if (!autoplayEnabled.value) {
		songStarted() // this will trigger on resume, TODO
	}
	useEventOff('player:bypassAutoPlay')
}

// If the user does not have a premium sub, the next condition checked is whether to show the modal or not.
// Then the next song is played if the user has free songs left
const goToNextSong = async () => {
	useEventEmit('playerEvent:interruptedOrCompleted')

	// If the player is paused due to auto play being set to false, send a bypass event
	if (isPausedByAutoPlay.value) {
		isPausedByAutoPlay.value = false
		useEventEmit('player:bypassAutoPlay')
	}
	await nextSong()
}

const nextUpClick = () => {
	const { segmentEvent } = useSegment()
	goToNextSong()
	segmentEvent('User Clicked Next Up')
}

// Space to pause/play
onKeyStroke(' ', (e) => {
	if (preventHotkeys.value || !hasSongPlaying.value) {
		return
	}
	e.preventDefault()
	if (isAudioPlaying.value) {
		pressPause()
	} else {
		pressPlay()
	}
}, { dedupe: true })

// Arrow right to skip forward 10 seconds
onKeyStroke('ArrowRight', (e) => {
	if (preventHotkeys.value || !hasSongPlaying.value) {
		return
	}
	e.preventDefault()
	skipAudioPlayer(10)
})

// Arrow right to rewind 10 seconds
onKeyStroke('ArrowLeft', (e) => {
	if (preventHotkeys.value || !hasSongPlaying.value) {
		return
	}
	e.preventDefault()
	rewindAudioPlayer(10)
})

// Arrow up to increase volume
onKeyStroke('ArrowUp', (e) => {
	if (preventHotkeys.value) {
		return
	}
	e.preventDefault()
	increaseVolume(10)
})

// Arrow down to decrease volume
onKeyStroke('ArrowDown', (e) => {
	if (preventHotkeys.value) {
		return
	}
	e.preventDefault()
	decreaseVolume(10)
})

const inside = ref(false)
const isInside = () => {
	inside.value = true
}

const isOutside = () => {
	inside.value = false
}

const setActiveItem = (item: Nullable<string>) => {
	activeItem.value = activeItem.value !== item ? item : null
	searchString.value = '' // queue search reset
}

useEventOn(('songVersionModal:toggle'), () => {
	isVariantModalOpen.value = !isVariantModalOpen.value
})

useEventOn(('playerEvent:error'), () => {
	setShowPlayer(false)
	$oruga.notification.open({
		message: t('player.error'),
		variant: 'warning'
	})
	playerLoading.value = false
	// Without a timeout the sudden playing of the next song would feel too abrupt
	setTimeout(() => {
		if (hasSongs.value) {
			goToNextSong()
		}
	}, 3000)
})

onClickOutside(target, () => {
	if (activeItem.value && !inside.value && !isVariantModalOpen.value) {
		activeItem.value = null
	}
})

const emit = defineEmits(['togglePlayerVisibility'])

const toggleShowPlayer = () => {
	if (inside.value) {
		return
	}
	if (isSecondScreenActive.value) {
		secondScreenStore.bringPopoutWindowToFront()
		if (!isMobile) {
			$oruga.notification.open({
				component: PlayerNotificationsPopupPlayer,
				variant: 'primary',
				icon: 'alert-circle-outline',
				position: 'bottom-left',
				type: 'info',
				rootClass: 'popup-player-notification',
				duration: 7000,
				queue: true
			})
		}
		return
	}
	emit('togglePlayerVisibility')
}
const nowPlaying = computed(() => firstInQueue.value ? useSongProperties(firstInQueue.value.entry) : null)
const stylesForMiniImage = computed(() => nowPlaying.value ? `background: ${(hasSongPlaying.value || isMediaLoading.value || playerLoading.value || countDownActive.value) ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.6) 100%), ' : ' '} url(${nowPlaying.value ? nowPlaying.value?.image : ' '}) no-repeat; background-size: cover;'` : '')
const freemiumText = computed(() => freemiumButtonHover.value ? settings?.value?.content?.get_premium_cta : (numberOfFreeSongsLeft.value > 0 ? t('button.freeSongs', { count: numberOfFreeSongsLeft.value }) : settings?.value?.content?.get_premium_cta))

onUnmounted(() => {
	useEventOff('playerEvent:error')
	useEventOff('songVersionModal:toggle')
	useEventOff('queue:contextMenu')
	useEventOff('player:closeSettings')
})

const minimizePlayer = () => {
	if (showPlayer.value) setShowPlayer(false)
}
</script>

<style lang="sass" scoped>
.MiniPlayer
	background-color: $transparent-white-12
	height: 88px
	position: fixed
	left: 0
	right: 0
	bottom: 0
.progress-wrap
	position: absolute
	top: 0
	width: 100%
.mini-queue-button
	height: 44px
	width: 44px
	border-radius: $radius-default
	padding: $spacing-8
	:deep(.button-wrapper)
		overflow: hidden
		white-space: nowrap
		text-overflow: ellipsis
	@media (min-width: $desktop)
		height: 64px
		width: 64px
	p
		@include fontSize(xs)
		display: none
	.icon
		margin: 0 auto !important
	&:not(.play)
		&:not(:hover)
			background-color: $transparent-white-8
	&.play
		margin: 0 $spacing-8
		@media (min-width: $tablet)
			margin: 0 $spacing-16
	@media (max-width: $desktop)
		&.settings
			margin-right: $spacing-8

	.button-wrapper
		span
			display: block
.wrap
	display: flex
	align-items: center
	justify-content: space-between
	padding: 20px $spacing-16 0
	@media (min-width: $mobile-small)
		.mini-queue-button
			width: 56px
	@media (min-width: $desktop)
		padding: $spacing-16 $spacing-8 0
		.mini-preview
			display: flex
		.mini-queue-button
			p
				display: inline
			&:not(.play)
				width: 84px
			&.play
				width: 100px
		.song-info
			max-width: calc(28vw - 93px)
	@media (min-width: $desktop)
		.mini-queue-button
			width: 104px
			&.play
				width: 136px
		.song-info
			max-width: calc(28vw - 72px)
	@media (min-width: $desktop)
		padding: $spacing-16 $spacing-24 0
		.song-info
			max-width: calc(33vw - 100px)
.preview-playing
	.song-info
		.song-title
			color: $primary
			&:hover
				color: $color-green-70
		.song-artists-wrap
			color: $color-green-80
			.song-artist
				color: inherit
				&:hover
					color: $color-green-90
.song-info
	max-width: 22vw
.mini-preview
	width: 64px
	height: 64px
	background-color: $transparent-white-12
	border-radius: $radius-default
	margin-right: $spacing-16
	display: flex
	align-items: center
	justify-content: center
	display: none
	position: relative
	.playing
		height: 20px
		width: auto
		z-index: 0
		span
			width: 2px
			background-color: $primary
			float: left
			margin: 0 1px
			transform: rotate(180deg)
			transform-origin: top
			position: relative
			top: 18px
			animation: play .7s linear 0s infinite alternate
			&:first-child
				animation-delay: .3s
				animation-duration: .4s
				height: 4px
				&:nth-of-type(2)
					animation-delay: .4s
					animation-duration: .3s
					height: 3px
			&:nth-of-type(3)
				animation-delay: .2s
				animation-duration: .4s
				height: 3px
			&:nth-of-type(4)
				animation-delay: .3s
				animation-duration: .3s
				height: 5px

	@keyframes play
		from
			height: 2px
		to
			height: 13px

	.animation-paused
		span
			animation-play-state: paused

.song-info-wrap, .queue-settings
	display: flex
	flex: 2
	&.is-mobile
		flex-grow: 0
		margin-right: $spacing-8
	@media (max-width: $desktop)
		flex: 1

.player-controls
	display: flex
	margin: 0
	text-align: center
	flex-wrap: nowrap
	@media (min-width: $desktop)
		margin: 0 $spacing-16

.queue-settings
	justify-content: flex-end
	align-items: center
	cursor: pointer
	@media (min-width: $desktop)
		margin-left: auto
		gap: $spacing-16
.song-info-wrap
	cursor: pointer
	align-items: center
	margin-right: auto
	.playing-in-second-screen
		opacity: 1
	&:not(.bypass-hover):hover
		.expand-icon
			opacity: 1
.mobile-controls
	@media (max-width: $tablet - 1)
		.song-info-wrap
			flex-grow: 1
			max-width: calc(100% - 144px)
			width: 100%
			.song-info
				max-width: 100%
		.player-controls, .queue-settings
			flex: 0
			flex-shrink: 0
			align-self: flex-end
		.player-controls
			padding: 0 $spacing-16
.song-artists-wrap
	white-space: nowrap
	text-overflow: ellipsis
	min-width: 0
	overflow: hidden
.song-info
	.song-title
		color: $color-grey-20
		@include fontSize(m)
		@include font(basier, medium)
.settings-wrap, .queue-wrap
	//- background-color: $transparent-black-56
	background-color: rgba($color-grey-80, .3) !important
	border-radius: $radius-default
	position: absolute
	right: $spacing-8
	bottom: calc(88px + $spacing-8) // mini player + spacing
	cursor: default
	overflow: hidden
	z-index: 3
	width: 500px
	padding: $spacing-16
	&.is-mobile
		left: 0px
		width: 100%

.settings-wrap
	@include backdropBlur(regular)
.queue-wrap
	width: 500px
	min-height: 304px
	padding: 20px $spacing-16
	@include backdropBlur(regular)
	overflow: visible

.empty-queue
	margin: $spacing-4 auto
	text-align: center

.song-img
	border-radius: $radius-default
	width: 48px
	height: auto
	margin-right: $spacing-16
.song-info-empty
	width: 50%
	span
		color: $color-grey-40

.search-info-top
	display: flex
	justify-content: space-between
	.left
		display: flex
		align-items: center
		justify-content: flex-start
	.right
		display: flex
		align-items: center

.song-title
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis
	display: block

.expand-icon, .playing-in-second-screen
	opacity: 0
	transition: opacity 200ms
	background-color: $transparent-black-56
	width: 100%
	height: 100%
	border-radius: $radius-default
	text-align: center
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	@include fontSize(xxs)
	backdrop-filter: blur(2px)
	position: absolute
	user-select: none
	z-index: 1
.playing-in-second-screen
	.green
		color: $color-green-50
.icon-and-text-wrapper
	position: absolute

.grey
	color: $color-grey-30
.sortable-chosen.ghost
	position: relative
	&::after
		content: ''
		position: absolute
		bottom: 0
		left: 0
		right: 0
		width: 100%
		height: 1px
		background-color: $primary
	.song-list-item
		opacity: .2

.queue-circle
	position: absolute
	top: -$spacing-8
	right: -$spacing-8
	min-width: 24px
	height: 24px
	padding: 2px 6px
	border-radius: 24px
	color: black
	@include fontSize(xs)
	background-color: $primary
	font-family: $system-font
	font-variant-numeric: tabular-nums
	font-weight: 600
	@media (min-width: $desktop)
		top: -4px
		right: -4px
	&.animate
		animation: bounce 0.5s ease-in-out

@keyframes bounce
	0%
			transform: scale(.9)
			background-color: $primary
	25%
			transform: scale(1)
			background-color: $color-green-50
			box-shadow: 0 0 0 2px rgba($color-green-80, 0.2)
	50%
			transform: scale(1.1)
			background-color: $color-green-50
			box-shadow: 0 0 0 5px rgba($color-green-80, 0.2)
	75%
			transform: scale(.9)
			background-color: $color-green-50
			box-shadow: 0 0 0 2px rgba($color-green-80, 0.2)
	100%
			transform: scale(1)
			background-color: $primary

.demo-cta, .freemium-songs-counter
	display: none
	@media (min-width: 1150px) //- starts overflowing with english copy
		display: flex
		justify-content: center
		align-items: center
		flex-grow: 1
.demo-cta-button, .freemium-songs-counter
	height: 28px
	text-align: center
	border-radius: $radius-default
	@include fontSize(xs)
	margin: 0 $spacing-8 0 $spacing-8
	:deep(.freemium-button)
		padding: $spacing-4 $spacing-16
		border-radius: $spacing-8
		.button-wrapper
			min-width: 150px
.mobile-button
	width: 56px
	height: 56px
	color: $transparent-white-8
	margin-top: $spacing-8
	.icon
		color: $color-grey-30
.song-controls
	display: flex
	width: 106px
	gap: 2px
	margin: 0 $spacing-8 0 $spacing-8
	.favorite-button
		width: 52px
	.ghost-button
		:deep(.is-transparent-dark)
			max-width: 52px
			background-color: transparent
			&:hover,
			&:active,
			&:focus
				background-color: $transparent-white-8
.up-next
	display: flex
	flex-direction: row
	gap: $spacing-16
	background-color: $transparent-white-8
	width: 320px
	height: 96px
	border-radius: $radius-default
	margin: $spacing-8
	padding: $spacing-16
	opacity: 0
	transform: translateY(100%)
	backdrop-filter: blur(24px)
	transition: opacity 0.4s ease, transform 0.4s ease, background-color 0.1s
	&.show-next-up
		opacity: 1
		transform: translateY(0)
	.play
		width: 64px
		height: 64px
		border-radius: $radius-default
		background-color: $transparent-white-8
		display: flex
		align-items: center
		justify-content: center
		.play-icon
			position: absolute
			color: $color-grey-30
			width: 28px !important
			height: 28px !important
			transition: color 0.3s ease
		.img
			position: relative
			border-radius: $radius-default
			overflow: hidden
			display: flex
			&:after
				position: absolute
				content: ''
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: $transparent-black-56
				transition: background 200ms
		&:hover
			.play-icon
				color: white
			.img:after
				background: rgba(black, .48)
	&:hover
		background-color: $transparent-white-16
		cursor: pointer
		.play-icon
			color: $color-grey-10
	.texts
		flex-direction: column
		max-width: 200px
		.up-next-title
			color: $color-grey-40
			@include fontSize(xs)
			text-transform: uppercase
			line-height: 1.2 !important
		.up-next-song
			color: $color-grey-20
			@include fontSize(m)
			@include font(basier, medium)
			text-overflow: ellipsis
			overflow: hidden
			white-space: nowrap
			display: block
		.up-next-artist
			color: $color-grey-30
			@include fontSize(s)
			line-height: 1.2 !important
			text-overflow: ellipsis
			overflow: hidden
			white-space: nowrap
			display: block

// Add transition styles
.fade-enter-active, .fade-leave-active
	transition: opacity 0.5s
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
	opacity: 0

.absolute
	position: absolute

.queue-loading
	position: absolute
	top: -6px
	right: -6px
	min-width: 24px
	height: 24px
</style>

<style lang="sass">
.settings-wrap
	.field-body
		.field
			display: flex
			justify-content: flex-end
.popup-player-notification
	padding: 16px 26px !important
	margin-left: -17px !important
</style>
